import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useLocation, useNavigate } from 'react-router-dom';
import Select from 'react-select';
import { Config } from '../.env.js';
import "./style.css";

const Form = () => {
  const location = useLocation();
  const { campaignName = '' } = location.state || {};
  const navigate = useNavigate();
  const [data, setData] = useState({});

  const [showMessage, setShowMessage] = useState(false);
  const [loading, setLoading] = useState(false);
  const [products, setProducts] = useState([{ name: '', quantity: '', ss: null, amount: '' }]);
  const [formData, setFormData] = useState(() => {
    // Load saved data from localStorage if available
    const savedData = localStorage.getItem('formData');
    return savedData ? JSON.parse(savedData) : {
      FullName: '',
      review: '',
      customMarketPlaceName: '',
      customForoum: '',
      whatsappGroup: '',
      city: '',
      state: '',
      AuthInstagram: '',
      AuthFacebook: '',
      AuthTwitter: '',
      AuthYouTube: '',
      gender: '',
      langaugeWrite: '',
      langaugeSpeek: '',
      collegeName: '',
      whatsapp: '',
      email: '',
      date: '',
      manager: '',
      pinCode: '',
      accountNumber: '',
      ifsc: '',
      BeneficiaryName: '',
      BankName: '',
      productSS: '',
      inputValue: '',
      quantity: '',
      productAmount: '',
      inputValue3: '',
      inputValue4: '',
      order: '',
      Phone: '',
      student: '',
      AuthCustomSocial: '',
      profileType: '',
      submitedDate: '',
      campaignName: '',
    };
  });
  useEffect(() => {
    localStorage.setItem('formData', JSON.stringify(formData));
  }, [formData]);

  const handleClearStorage = () => {
    localStorage.clear();
    setFormData({
      FullName: '',
      review: '',
      customMarketPlaceName: '',
      customForoum: '',
      whatsappGroup: '',
      city: '',
      state: '',
      AuthInstagram: '',
      AuthFacebook: '',
      AuthTwitter: '',
      AuthYouTube: '',
      gender: '',
      langaugeWrite: '',
      langaugeSpeek: '',
      collegeName: '',
      whatsapp: '',
      email: '',
      date: '',
      manager: '',
      pinCode: '',
      accountNumber: '',
      ifsc: '',
      BeneficiaryName: '',
      BankName: '',
      productSS: '',
      inputValue: '',
      quantity: '',
      productAmount: '',
      inputValue3: '',
      inputValue4: '',
      order: '',
      Phone: '',
      student: '',
      AuthCustomSocial: '',
      profileType: '',
      submitedDate: '',
      campaignName: '',
    });
  };
  useEffect(() => {
    if (campaignName) {
      const fetchData = async () => {
        try {
          const response = await axios.get(`${Config.API_URL}/api/FormData/${campaignName}`);


          console.log('Fetched data:', response.data);


          const filteredData = response.data.reduce((acc, item) => ({ ...acc, ...item }), {});

          setData(filteredData);
        } catch (error) {
          console.error('Error fetching form data:', error);
        }
      };
      fetchData();
    }
  }, [campaignName]);

  // const handleFormChange = (event) => {
  //   const { name, value, type, checked } = event.target;

  //   setFormData(prevData => ({
  //     ...prevData,
  //     [name]: type === 'checkbox' ? checked : value,
  //   }));
  // };
  const handleFormChange = (eventOrValue, actionMeta) => {
    // Check if this is the Select component's event
    if (Array.isArray(eventOrValue)) {
      const { name } = actionMeta; // Get the 'name' of the Select input
      setFormData(prevData => ({
        ...prevData,
        [name]: eventOrValue, // Update with the selected options array
      }));
    } else {
      // Handle regular inputs
      const { name, value, type, checked } = eventOrValue.target;
      setFormData(prevData => ({
        ...prevData,
        [name]: type === 'checkbox' ? checked : value,
      }));
    }
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    setShowMessage(true);
    setLoading(true);
    const formDataToSubmit = {
      ...formData,
      student: formData.student === true, // Ensure it's a boolean
      campaignName,
    };
    try {
      const response = await axios.post(`${Config.API_URL}/api/order-data`, formDataToSubmit);
      console.log('Data saved:', response.data);

      // Optionally navigate after a successful submission
      setTimeout(() => {
        navigate('/live-user');
      }, 1500);
    } catch (error) {
      console.error('Error saving data:', error);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const response = await axios.get(`${Config.API_URL}/api/product`, {
          params: { campaignName }, // Send campaign name as a query parameter
        });
        setProducts(response.data); // Set fetched products to state
      } catch (error) {
        console.error("Error fetching products:", error);
      }
    };

    if (campaignName) {
      fetchProducts(); // Fetch products when campaignName changes
    }
  }, [campaignName]);
  const handleFileUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
      // You can handle file processing or uploading logic here
      // For example, you can save the file to state or send it to a server
      console.log(file);
    }
  };
  let hasValNumber = /^[0-9]{1,10}$/.test(formData.Phone);
  let hasValWhatsapp = /^[0-9]{1,10}$/.test(formData.whatsapp);
  let hasValEmail = /^[a.-zA.-Z0.-9]+@[a.-zA.-Z0.-9]+\.[A-Za-z]+$/.test(formData.email);
  const validOrderId = /^[a-zA-Z0-9]*$/.test(formData.order);
  const languageOptions = [
    { value: 'English', label: 'English' },
    { value: 'Hindi', label: 'Hindi' },
    { value: 'Marathi', label: 'Marathi' },
    { value: 'Gujrati', label: 'Gujrati' },
    { value: 'Kannada', label: 'Kannada' },
    { value: 'Manipuri', label: 'Manipuri' },

    { label: 'Mizo', value: 'Mizo' },
    { label: 'Kokborok', value: 'Kokborok' },
    { label: 'Assamese', value: 'Assamese' },
    { label: 'Bhojpuri', value: 'Bhojpuri' },
    { label: 'Konkani', value: 'Konkani' },
    { label: 'Dogri', value: 'Dogri' },
    { label: 'Gujarati', value: 'Gujarati' },
    { label: 'Marathi', value: 'Marathi' },
    { label: 'Sindhi', value: 'Sindhi' },
    { label: 'Nepali', value: 'Nepali' },
    { label: 'Urdu', value: 'Urdu' },
    { label: 'Maithli', value: 'Maithli' },
    { label: 'Malayalam', value: 'Malayalam' },
    { label: 'Odia', value: 'Odia' },
    { label: 'Sanskrit', value: 'Sanskrit' },
    { label: 'Italian', value: 'Italian' },
    { label: 'Hindi', value: 'Hindi' },
    { label: 'Bengali', value: 'Bengali' },
    { label: 'Tamil', value: 'Tamil' },
    { label: 'Telugu', value: 'Telugu' },
    { label: 'Rajasthani', value: 'Rajasthani' },
    { label: 'Chhattisgarhi', value: 'Chhattisgarhi' },
    { label: 'Magahi', value: 'Magahi' },
    { label: 'Khortha/Khotta', value: 'Khortha/Khotta' },
    { label: 'Marwari', value: 'Marwari' },
    { label: 'Bundeli/Bundel khandi', value: 'Bundeli/Bundel khandi' },
    { label: 'Lamani/Lambadi', value: 'Lamani/Lambadi' },
    { label: 'Wagdi', value: 'Wagdi' },
    { label: 'Awadhi', value: 'Awadhi' },
    { label: 'Mewari', value: 'Mewari' },
    { label: 'Sadan/Sadri', value: 'Sadan/Sadri' },
    { label: 'Malvi', value: 'Malvi' },
    { label: 'Sambalpuri', value: 'Sambalpuri' },
    { label: 'Gondi', value: 'Gondi' },
    { label: 'Hara/Harauti', value: 'Hara/Harauti' },
    { label: 'Bhili/Bhilodi', value: 'Bhili/Bhilodi' },
    { label: 'Pahari', value: 'Pahari' },
    { label: 'Surgujia', value: 'Surgujia' },
    { label: 'Tulu', value: 'Tulu' },
    { label: 'Kurukh/Oraon', value: 'Kurukh/Oraon' },
    { label: 'Kumauni', value: 'Kumauni' },

    { label: 'Bagri', value: 'Bagri' },
    { label: 'Ahirani', value: 'Ahirani' },
    { label: 'Banjari', value: 'Banjari' },
    { label: 'Brajbhasha', value: 'Brajbhasha' },
    { label: 'Dhundhari', value: 'Dhundhari' },
    { label: 'Bodo/Boro', value: 'Bodo/Boro' },
    { label: 'Ho', value: 'Ho' },
    { label: 'Gojri/Gujjari/Gujar', value: 'Gojri/Gujjari/Gujar' },
    { label: 'Mundari', value: 'Mundari' },
    { label: 'Garo', value: 'Garo' },
    { label: 'Kangri', value: 'Kangri' },
    { label: 'Khasi', value: 'Khasi' },
    { label: 'Kachchhi', value: 'Kachchhi' },
    { label: 'Surjapuri', value: 'Surjapuri' },
    { label: 'Nimadi', value: 'Nimadi' },
    { label: 'Garhwali', value: 'Garhwali' },

    { label: 'Santali', value: 'Santali' },
    { label: 'Kui', value: 'Kui' },
    { label: 'Lushai/Mizo', value: 'Lushai/Mizo' },
    { label: 'Halabi', value: 'Halabi' },
    { label: 'Korku', value: 'Korku' },
    { label: 'Miri/Mishing', value: 'Miri/Mishing' },
    { label: 'Munda', value: 'Munda' },
    { label: 'Karbi/Mikir', value: 'Karbi/Mikir' },
    { label: 'Koya', value: 'Koya' },
    { label: 'Ao', value: 'Ao' },
    { label: 'Savara', value: 'Savara' },
    { label: 'Konyak', value: 'Konyak' },
    { label: 'Kharia', value: 'Kharia' },
    { label: 'Malto', value: 'Malto' },

    { label: 'Nissi/Dafla', value: 'Nissi/Dafla' },
    { label: 'Kashmiri', value: 'Kashmiri' },
    { label: 'Adi', value: 'Adi' },
    { label: 'Thado', value: 'Thado' },
    { label: 'Lotha', value: 'Lotha' },
    { label: 'Coorgi/Kodagu', value: 'Coorgi/Kodagu' },
    { label: 'Rabha', value: 'Rabha' },
    { label: 'Tangkhul', value: 'Tangkhul' },
    { label: 'Kisan', value: 'Kisan' },
    { label: 'Angami', value: 'Angami' },
    { label: 'Phom', value: 'Phom' },
    { label: 'Kolami', value: 'Kolami' },
    { label: 'Khond/Kondh', value: 'Khond/Kondh' },
    { label: 'Dimasa', value: 'Dimasa' },
    { label: 'Ladakhi', value: 'Ladakhi' },
    { label: 'Sema', value: 'Sema' },

    { value: 'Other', label: 'Other' }
  ];
  const customStyles1 = {
    control: (provided) => ({
      ...provided,
      borderRadius: '20px',
    }),
    menu: (provided) => ({
      ...provided,
      borderRadius: '20px',
    }),
    option: (provided, state) => ({
      ...provided,
      borderRadius: state.isFocused ? '20px' : '0',
    }),
    multiValue: (provided) => ({
      ...provided,
      borderRadius: '20px',
    }),
    multiValueLabel: (provided) => ({
      ...provided,
      borderRadius: '20px',
    }),
    multiValueRemove: (provided) => ({
      ...provided,
      borderRadius: '20px',
    }),
  };
  return (
    <div className='container'>
      {loading && (
        <div className="loader-overlay">
          <div className="loader"></div>
        </div>
      )}
      <form onSubmit={handleSubmit}>
        <button type="button" className="button is-danger" onClick={handleClearStorage}>
          Clear All Data
        </button>
        <h2>Order Form for {campaignName}</h2>


        {data.FullName === true && (
          <div className="field">
            <label className="label">Your Full Name</label>
            <div className="control">
              <input
                className="input is-rounded"
                type="text"
                name="FullName"
                placeholder="Name"
                value={formData.FullName}
                onChange={handleFormChange}
                required
              />
            </div>
          </div>
        )}
        {data.AuthInstagram === true && (
          <div className="field">
            <label className="label">Your Instagram User Name</label>
            <div className="control">
              <input
                className="input is-rounded"
                type="text"
                name="AuthInstagram"
                placeholder="Username"
                value={formData.AuthInstagram}
                onChange={handleFormChange}
                required
              />
            </div>
          </div>
        )}
        {data.AuthYouTube === true && (
          <div className="field">
            <label className="label">Your YouTube User Name</label>
            <div className="control">
              <input
                className="input is-rounded"
                type="text"
                name=" AuthYouTube"
                placeholder="Username"
                value={formData.AuthYouTube}
                onChange={handleFormChange}
                required
              />
            </div>
          </div>
        )}
        {data.AuthTwitter === true && (
          <div className="field">
            <label className="label">Your Twitter User Name</label>
            <div className="control">
              <input
                className="input is-rounded"
                type="text"
                name="AuthTwitter"
                placeholder="Username"
                value={formData.AuthTwitter}
                onChange={handleFormChange}
                required
              />
            </div>
          </div>
        )}

        {data.AuthFacebook === true && (
          <div className="field">
            <label className="label">Your Facebook User Name</label>
            <div className="control">
              <input
                className="input is-rounded"
                type="text"
                name="AuthFacebook"
                placeholder="Username"
                value={formData.AuthFacebook}
                onChange={handleFormChange}
                required
              />
            </div>
          </div>
        )}
        {data.AuthCustomSocial && data.AuthCustomSocial !== false && data.AuthCustomSocial.trim() !== "" && (
          <div className='field'>
            <label className="label">  Your {data.AuthCustomSocial} User Name</label>
            <div className="control">
              <input
                className="input is-rounded"
                type="text"
                name="AuthCustomSocial"
                placeholder="User Name"
                value={formData.AuthCustomSocial}
                onChange={handleFormChange}
                required
              />
            </div>
          </div>
        )}

        {data.customMarketPlaceName && data.customMarketPlaceName !== false && data.customMarketPlaceName.trim() !== "" && (
          <div className='field'>
            <label className="label">{data.customMarketPlaceName} Account Name</label>
            <div className="control">
              <input
                className="input is-rounded"
                type="text"
                name="customMarketPlaceName"
                placeholder="Account Name"
                value={formData.customMarketPlaceName}
                onChange={handleFormChange}
                required
              />
            </div>
          </div>
        )}
        {data.customForoum && data.customForoum !== false && data.customForoum.trim() !== "" && (
          <div className='field'>
            <label className="label">{data.customForoum} Profile Link</label>
            <div className="control">
              <input
                className="input is-rounded"
                type="text"
                name="customForoum"
                placeholder="Profile Link"
                value={formData.customForoum}
                onChange={handleFormChange}
                required
              />
            </div>
          </div>
        )}
        {data.email === true && (
          <div className="field">
            <label className="label"> Your Email</label>
            <div className="control">
              <input
                className="input is-rounded"
                type="text"
                name="email"
                placeholder="abc@abc.com"
                value={formData.email}
                onChange={handleFormChange}
                required
              />
            </div>
            {formData.email && !hasValEmail && (
              <div>
                <small
                  className={`ml-1 mt-1  ${!hasValNumber && formData.email !== '' ? 'has-text-danger' : 'has-text-success'}`}>
                  {!hasValNumber && formData.email !== '' ? 'Please Enter valid Mobile number' : ''}
                </small>
              </div>
            )}

          </div>
        )}
        {data.Phone === true && (
          <div className="field">
            <label className="label">Your Phone Number</label>
            <div className="control">
              <input
                className="input is-rounded"
                type="text"
                name="Phone" // Removed the space before 'Phone'
                placeholder="9999999999"
                value={formData.Phone}
                onChange={handleFormChange}
                required
              />
            </div>
            {formData.Phone && !hasValNumber && (
              <div>
                <small
                  className={`ml-1 mt-1  ${!hasValNumber && formData.Phone !== '' ? 'has-text-danger' : 'has-text-success'}`}>
                  {!hasValNumber && formData.Phone !== '' ? 'Please Enter valid Mobile number' : ''}
                </small>
              </div>
            )}
          </div>
        )}
        {data.whatsapp === true && (
          <div className="field">
            <label className="label"> Your Whatsapp Number</label>
            <div className="control">
              <input
                className="input is-rounded"
                type="text"
                name="whatsapp"
                placeholder="9999999999"
                value={formData.whatsapp}
                onChange={handleFormChange}
                required
              />
            </div>
            {formData.whatsapp && !hasValWhatsapp && (
              <div>
                <small
                  className={`ml-1 mt-1  ${!hasValNumber && formData.whatsapp !== '' ? 'has-text-danger' : 'has-text-success'}`}>
                  {!hasValNumber && formData.whatsapp !== '' ? 'Please Enter valid Whatsapp Number number' : ''}
                </small>
              </div>
            )}
          </div>
        )}
        {data.gender === true && (
          <div className="field">
            <label className="label">Your Gender</label>
            <div className="control">
              <label className="radio">
                <input
                  type="radio"
                  name="gender"
                  value="male"
                  checked={formData.gender === 'male'}
                  onChange={handleFormChange}
                  required
                />
                Male
              </label>
              <label className="radio">
                <input
                  type="radio"
                  name="gender"
                  value="female"
                  checked={formData.gender === 'female'}
                  onChange={handleFormChange}
                />
                Female
              </label>
              <label className="radio">
                <input
                  type="radio"
                  name="gender"
                  value="other"
                  checked={formData.gender === 'other'}
                  onChange={handleFormChange}
                />
                Other
              </label>
            </div>
          </div>
        )}
        {data.langaugeSpeek === true && (

          <div className='field'>
            <div className="control">
              <div className='colomn'>
                <label className="label"> Which language can you write?</label>
                <Select
                  isMulti
                  value={formData.langaugeSpeek}
                  name="langaugeSpeek"
                  onChange={(selectedOptions) =>
                    handleFormChange(selectedOptions, { name: 'langaugeSpeek' })
                  }
                  options={languageOptions}
                  className="basic-multi-select is-rounded"
                  classNamePrefix="select"
                  styles={customStyles1}
                />
              </div>
            </div>
          </div>
        )}
        {data.langaugeWrite === true && (
          <div className='field'>
            <div className="control">
              <div className='colomn'>
                <label className="label"> Which language can you speak?</label>
                <Select
                  isMulti
                  value={formData.langaugeWrite}
                  name="langaugeSpeek"
                  onChange={(selectedOptions) =>
                    handleFormChange(selectedOptions, { name: 'langaugeWrite' })
                  }
                  options={languageOptions}
                  className="basic-multi-select is-rounded"
                  classNamePrefix="select"
                  styles={customStyles1}
                />
              </div>
            </div>
          </div>
        )}

        {data.profileType && data.profileType !== false && data.profileType.trim() !== "" && (
          <div className='field'>
            <label className="label">Is your profile type {data.profileType}?</label>
            <div className="control">
              <label className="radio">
                <input
                  type="radio"
                  name="profileType"
                  value="yes"
                  checked={formData.profileType === 'yes'}
                  onChange={handleFormChange}
                />
                Yes
              </label>
              <label className="radio">
                <input
                  type="radio"
                  name="profileType"
                  value="no"
                  checked={formData.profileType === 'no'}
                  onChange={handleFormChange}
                />
                No
              </label>
            </div>
          </div>
        )}

        {data.order === true && (
          <div className="field">
            <label className="label">Order Id</label>
            <div className="control">
              <input
                className="input is-rounded"
                type="text"
                name="order"
                placeholder="Order ID"
                value={formData.order}
                onChange={handleFormChange}
                required
              />
            </div>
            {formData.order && !validOrderId && (
              <div>
                <small
                  className={`ml-1 mt-1  ${!validOrderId && formData.order !== '' ? 'has-text-danger' : 'has-text-success'}`}>
                  {!validOrderId && formData.order !== '' ? 'Enter Valid order id without _,-,@' : ''}
                </small>
              </div>
            )}
          </div>
        )}

        {data.date === true && (
          <div className="field">
            <label className="label"> Order Date</label>
            <div className="control">
              <input
                className="input is-rounded"
                type="text"
                name="date"
                placeholder="dd-mm-yyyy"
                value={formData.date}
                onChange={handleFormChange}
                required
              />
            </div>
          </div>
        )}
        {data.submitedDate && data.submitedDate.trim() !== "" && (
          <div className="field">
            <label className="label">Submission Date</label>
            <div className="control">
              <input
                className="input is-rounded"
                type="text"
                name="submitedDate"
                placeholder={`Hint: ${data.submitedDate}`} // Hint based on `data.submitedDate`
                value={formData.submitedDate}
                onChange={handleFormChange}
                required
              />
            </div>
            <p className="help">Please enter the date in the format dd/mm/yyyy.</p> {/* Optional instructions */}
          </div>
        )}
        {data.review && data.review !== false && data.review.trim() !== "" && (
          <div className='field'>
            <label className="label"> How Many Reviews you do per month</label>
            <div className="control">
              <input
                className="input is-rounded"
                type="text"
                name="review"
                placeholder="Number of Reviews"
                value={formData.review}
                onChange={handleFormChange}
                required
              />
            </div>
          </div>
        )}

        {/* Student Checkbox */}
        {data.student === true && (
          <div className="field">
            <label className="checkbox">
              <input
                type="checkbox"
                checked={formData.student || false}  // Bind checkbox to formData
                onChange={(e) => setFormData({ ...formData, student: e.target.checked })}
                required
              />
              Are you a Student?
            </label>
          </div>
        )}

        {/* Conditionally render College Name input if student is true and data.college is true */}
        {formData.student && data.college === true && (
          <div className="field">
            <label className="label">What is your College Name?</label>
            <div className="control">
              <input
                className="input is-rounded"
                type="text"
                name="collegeName"  // New field for college name
                placeholder="College Name"
                value={formData.collegeName}  // Bind college name to formData
                onChange={handleFormChange}  // Use existing handleFormChange
                required
              />
            </div>
          </div>
        )}
        {data.city === true && (
          <div className='field'>
            <label className="label">City</label>
            <div className="control">
              <input
                className="input is-rounded"
                type="text"
                name="city"
                placeholder="City"
                value={formData.city}
                onChange={handleFormChange}
                required
              />
            </div>
          </div>
        )}
        {data.state === true && (
          <div className='field'>
            <label className="label">State</label>
            <div className="control">
              <input
                className="input is-rounded"
                type="text"
                name="state"
                placeholder="City"
                value={formData.state}
                onChange={handleFormChange}
                required
              />
            </div>
          </div>
        )}
        {data.pinCode === true && (
          <div className="field">
            <label className="label"> Pin Code</label>
            <div className="control">
              <input
                className="input is-rounded"
                type="text"
                name="pinCode"
                placeholder="123456"
                value={formData.pinCode}
                onChange={handleFormChange}
                required
              />
            </div>
          </div>
        )}
        {data.accountNumber === true && (
          <div className="field">
            <label className="label">Bank Account Number</label>
            <div className="control">
              <input
                className="input is-rounded"
                type="text"
                name="accountNumber"
                placeholder="Bank Account Number"
                value={formData.date}
                onChange={handleFormChange}
                required
              />
            </div>
          </div>
        )}
        {data.ifsc === true && (
          <div className="field">
            <label className="label"> Ifsc Code</label>
            <div className="control">
              <input
                className="input is-rounded"
                type="text"
                name="ifsc"
                placeholder="Ifsc Code"
                value={formData.date}
                onChange={handleFormChange}
                required
              />
            </div>
          </div>
        )}
        {data.BeneficiaryName === true && (
          <div className="field">
            <label className="label"> Beneficiary Name</label>
            <div className="control">
              <input
                className="input is-rounded"
                type="text"
                name="BeneficiaryName"
                placeholder=" Beneficiary Name"
                value={formData.BeneficiaryName}
                onChange={handleFormChange}
                required
              />
            </div>
          </div>
        )}
        {data.BankName === true && (
          <div className="field">
            <label className="label">Bank Name</label>
            <div className="control">
              <input
                className="input is-rounded"
                type="text"
                name="BankName"
                placeholder="Bank Name"
                value={formData.BankName}
                onChange={handleFormChange}
                required
              />
            </div>
          </div>
        )}
        {data.upi === true && (
          <div className="field">
            <label className="label">UPI ID</label>
            <div className="control">
              <input
                className="input is-rounded"
                type="text"
                name="upi"
                placeholder="UPI ID"
                value={formData.upi}
                onChange={handleFormChange}
                required
              />
            </div>
          </div>
        )}




        {/* {data.submitedDate && data.submitedDate !== false && data.submitedDate.trim() !== "" && (
          <div className='field'>
            <label className="label">Submited Date {data.submitedDate}</label>
            <div className="control">
              <input
                className="input is-rounded"
                type="text"
                name="submitedDate"
                placeholder="dd/mm/yyyy"
                value={formData.submitedDate}
                onChange={handleFormChange}
                required
              />
            </div>
          </div>
        )} */}

        {/* Only render if customMarketPlaceName is true */}

        {/* Only render if customForoum is true */}

        {data.productSS === true && (
          <div className='field'>
            {/* Additional input field */}
            <div className='field'>
              <label className="label">{data.inputValue}</label>
              <div className="control">
                <div className="select is-rounded">
                  <select
                    name="inputValue"
                    value={formData.inputValue}
                    onChange={handleFormChange}
                    required
                  >
                    <option value="" disabled>Select a product</option>
                    {products.map((product) => (
                      <option key={product._id} value={product.productName}>
                        {product.productName}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            </div>
            <div className='field'>
              <label className="label">{data.inputValue1}</label>
              <div className="control">
                <input
                  className="input is-rounded"
                  type="text"
                  name="quantity"
                  placeholder="Quantity"
                  value={formData.quantity}
                  onChange={handleFormChange}
                  required
                />
              </div>
            </div>
            <div className='field'>
              <label className="label">{data.inputValue2}</label>
              <div className="control">
                <input
                  className="input is-rounded"
                  type="text"
                  name="productAmount"
                  placeholder="Product Amount"
                  value={formData.productAmount}
                  onChange={handleFormChange}
                  required
                />
              </div>
            </div>
            <div className='field'>
              <label className="label">{data.inputValue3}</label>
              <div className="control">
                <input
                  className="input is-rounded"
                  type="file"
                  name="inputValue3"
                  accept="image/*" // Only allow image files
                  onChange={handleFileUpload} // Use a separate handler for file uploads
                  required
                />
              </div>
            </div>

          </div>
        )}

        {/* Only render if whatsappGroup is true */}
        {data.whatsappGroup === true && (
          <div className="field">
            <label className="label">Have You Joined Whatsapp Group</label>
            <label className="radio">
              <input
                type="radio"
                name="whatsappGroup"
                value="yes"
                checked={formData.whatsappGroup === 'yes'}
                onChange={handleFormChange}
                required
              />
              Yes
            </label>
            <label className="radio">
              <input
                type="radio"
                name="whatsappGroup"
                value="no"
                checked={formData.whatsappGroup === 'no'}
                onChange={handleFormChange}
                required
              />
              No
            </label>
          </div>
        )}

        {/* Only render if city is true */}

        <div className="field is-small">
          <label className="checkbox">
            <input
              type="checkbox"

            />
            Agree to{' '}
            <a href="//hobo.video/terms-conditions-for-an-influencer" target="_blank" rel="noopener noreferrer">
              Terms and Conditions
            </a>
          </label>
        </div>
        <div className="field">
          <div className="control">
            <button className="button is-primary is-fullwidth mt-5" type="submit">
              {loading ? (
                <div className="loader"></div> // Loader
              ) : (
                'Submit'
              )}
            </button>
          </div>
        </div>
      </form>
      {showMessage && (
        <div className="thank-you-message">
          <h1>Form Submited successfully</h1>
          <p>Thank you for submitting the form!</p>
        </div>
      )}
    </div>
  );
};

export default Form;
