import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import logo from "./img/Logo (1).png";
import "./style.css";

const Home = () => {
    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate();

    const handleNavigation = (path) => {
        setIsLoading(true); // Show loader
        setTimeout(() => {
            navigate(path); // Navigate after showing loader
            setIsLoading(false); // Hide loader
        }, 100); // Simulate a delay for loader
    };

    return (
        <div className="imgfull">
            <div className="container1 has-text-centered" style={{ paddingBottom: "18rem" }}>
                {/* <div className="logo-card">
                    <figure className="image is-128x128">
                        <img src={logo} alt="Logo" />
                    </figure>
                </div> */}
                <h1 className="title mt-3 has-text-white">Crowd App</h1>
                <div className="mt-5">
                    {/* Loader conditionally rendered */}
                    {isLoading ? (
                        <div className="loader"></div>
                    ) : (
                        <>
                            <button
                                className="button is-primary is-medium mx-3"
                                onClick={() => handleNavigation('/signin')}
                            >
                                Login
                            </button>
                            <button
                                className="button is-primary is-medium mx-3"
                                onClick={() => handleNavigation('/signup')}
                            >
                                Signup
                            </button>
                        </>
                    )}
                </div>
            </div>
        </div>
    );
};

export default Home;
