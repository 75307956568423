import React, { useState, useEffect } from 'react';
import axios from 'axios';

const BankDropdown = () => {
    const [banks, setBanks] = useState([]);
    const [selectedBank, setSelectedBank] = useState('');

    useEffect(() => {
        const fetchBanks = async () => {
            try {
                const response = await axios.get('http://localhost:7002/api/banks');
                setBanks(response.data);
            } catch (error) {
                console.error("Error fetching banks:", error);
            }
        };
        fetchBanks();
    }, []);

    const handleChange = (e) => setSelectedBank(e.target.value);

    return (

        <div className="control">
            <div className="select is-rounded">
                <select value={selectedBank} onChange={handleChange}>
                    <option value="">Select a Bank</option>
                    {banks.map((bank, index) => (
                        <option key={index} value={bank.name}>
                            {bank.name}
                        </option>
                    ))}
                </select>
            </div>
        </div>

    );
};

export default BankDropdown;
// import React, { useState } from 'react';
// import axios from 'axios';

// const ValidationForm = () => {
//     // State for UPI Validation
//     const [upiId, setUpiId] = useState('');
//     const [upiValidation, setUpiValidation] = useState(null); // Holds validation result for UPI

//     // State for Bank Account Validation
//     const [accountNumber, setAccountNumber] = useState('');
//     const [ifsc, setIfsc] = useState('');
//     const [bankValidation, setBankValidation] = useState(null); // Holds validation result for Bank

//     // Validate UPI ID
//     const validateUpi = async () => {
//         try {
//             const response = await axios.post('http://localhost:7002/api/validate-upi', { upiId });
//             setUpiValidation({ verified: true, name: response.data.data.name });
//         } catch (error) {
//             setUpiValidation({ verified: false, error: error.response.data.message });
//         }
//     };

//     // Validate Bank Account
//     const validateBank = async () => {
//         try {
//             const response = await axios.post('http://localhost:7002/api/validate-bank', {
//                 accountNumber,
//                 ifsc,
//             });
//             setBankValidation({ verified: true, name: response.data.data.name });
//         } catch (error) {
//             setBankValidation({ verified: false, error: error.response.data.message });
//         }
//     };

//     return (
//         <div style={{ padding: '20px', maxWidth: '500px', margin: '0 auto' }}>
//             <h2>Real-Time Validation</h2>

//             {/* UPI Validation */}
//             <div style={{ marginBottom: '20px' }}>
//                 <label htmlFor="upiId">Enter UPI ID:</label>
//                 <input
//                     type="text"
//                     id="upiId"
//                     value={upiId}
//                     onChange={(e) => setUpiId(e.target.value)}
//                     onBlur={validateUpi} // Validate UPI on input blur
//                     style={{ display: 'block', width: '100%', marginBottom: '5px', padding: '8px' }}
//                 />
//                 {upiValidation && (
//                     <div style={{ color: upiValidation.verified ? 'green' : 'red' }}>
//                         {upiValidation.verified
//                             ? `Verified! Name: ${upiValidation.name}`
//                             : `Error: ${upiValidation.error}`}
//                     </div>
//                 )}
//             </div>

//             {/* Bank Account Validation */}
//             <div>
//                 <label htmlFor="accountNumber">Enter Account Number:</label>
//                 <input
//                     type="text"
//                     id="accountNumber"
//                     value={accountNumber}
//                     onChange={(e) => setAccountNumber(e.target.value)}
//                     style={{ display: 'block', width: '100%', marginBottom: '5px', padding: '8px' }}
//                 />
//                 <label htmlFor="ifsc">Enter IFSC Code:</label>
//                 <input
//                     type="text"
//                     id="ifsc"
//                     value={ifsc}
//                     onChange={(e) => setIfsc(e.target.value)}
//                     onBlur={validateBank} // Validate Bank on input blur
//                     style={{ display: 'block', width: '100%', marginBottom: '5px', padding: '8px' }}
//                 />
//                 {bankValidation && (
//                     <div style={{ color: bankValidation.verified ? 'green' : 'red' }}>
//                         {bankValidation.verified
//                             ? `Verified! Name: ${bankValidation.name}`
//                             : `Error: ${bankValidation.error}`}
//                     </div>
//                 )}
//             </div>
//         </div>
//     );
// };

// export default ValidationForm;
