import React, { useState, useEffect } from 'react';
import { Config } from '../.env.js';
import { toast } from 'react-toastify';

const EditForm = ({ closeModal, selectedUser, updateUserTable }) => {
    const [userName, setUserName] = useState('');
    const [email, setEmail] = useState('');
    const [role, setRole] = useState('');
    const [userId, setUserId] = useState('');
    const [users, setUsers] = useState([]);

    useEffect(() => {
        if (selectedUser) {
            setUserName(selectedUser.userName);
            setEmail(selectedUser.email);
            setRole(selectedUser.role);
            setUserId(selectedUser._id); // Ensure the correct ID field is used
        }
    }, [selectedUser]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        console.log('Updating user:', { userId, userName, email, role });

        try {
            const response = await fetch(`${Config.API_URL}/api/users/${userId}`, {
                method: 'PUT',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ userName, email, role }),
            });

            if (!response.ok) {
                throw new Error(`Failed to update user: ${response.statusText}`);
            }

            const result = await response.json(); // Fetch updated user data from response
            const updatedUser = result.updatedUser; // Extract the updatedUser field

            console.log('User updated successfully:', updatedUser);
            updateUserTable(updatedUser);  // Pass updatedUser wrapped in an object

            toast.success('User updated successfully');
            // updateUserTable(updatedUser);
            closeModal();
        } catch (error) {
            console.error('Error updating user:', error);
        }
    };


    const handleDelete = async () => {
        if (!window.confirm('Are you sure you want to delete this user?')) {
            return;
        }
        // const updateUserTable = (updatedUser) => {
        //     // Update the user list in real-time
        //     setUsers((prevUsers) =>
        //         prevUsers.map((user) => (user._id === updatedUser._id ? updatedUser : user))
        //     );
        // };
        try {
            const response = await fetch(`${Config.API_URL}/api/users/${userId}`, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json'
                }
            });

            if (!response.ok) {
                throw new Error('Failed to delete user');
            }

            toast.success('User deleted successfully');
            closeModal();
        } catch (error) {
            console.error('Error deleting user:', error.message);
        }
    };

    const handleCancelEdit = () => {
        closeModal();
    };

    return (
        <div className="modal is-active" style={{ position: 'fixed', top: 0, left: 0, width: '100%', height: '100%', backgroundColor: 'rgba(0, 0, 0, 0.5)' }}>
            <div className="modal-content" style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', backgroundColor: 'white', padding: '20px', borderRadius: '8px', width: '80%', maxWidth: '800px' }}>
                <button className="delete" aria-label="close" onClick={handleCancelEdit}></button>
                <div className="card">
                    <header className="card-header">
                        <p className="card-header-title">Edit User</p>
                    </header>
                    <div className="card-content">
                        <form onSubmit={handleSubmit}>
                            <div className='field'>
                                <label className='label has-text-left'>Name</label>
                                <div className='control'>
                                    <input
                                        className='input is-rounded'
                                        type='text'
                                        placeholder='User Name'
                                        value={userName}
                                        onChange={(e) => setUserName(e.target.value)}
                                    />
                                </div>
                            </div>
                            <div className='field'>
                                <label className='label has-text-left'>Email Id</label>
                                <div className='control'>
                                    <input
                                        className='input is-rounded'
                                        type='text'
                                        placeholder='Email ID'
                                        value={email}
                                        onChange={(e) => setEmail(e.target.value)}
                                    />
                                </div>
                            </div>
                            <div className='field'>
                                <label className='label has-text-left'>Role</label>
                                <div className='control'>
                                    <div className='select'>
                                        <select
                                            style={{ borderRadius: '20px', width: "900px" }}
                                            value={role}
                                            onChange={(e) => setRole(e.target.value)}
                                        >
                                            <option value=''>Select Role</option>
                                            <option value='Admin'>Admin</option>
                                            <option value='Lead'>Lead</option>
                                            <option value='Campaign_Manger'>Campaign Manger</option>
                                            <option value='Pyment_Approver'>Pyment Approver</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div className='field'>
                                <div className='control'>
                                    <button className='button is-primary is-rounded is-fullwidth' disabled={!userName || !email || !role}>Update</button>
                                </div>
                            </div>
                        </form>
                        <div className='field' style={{ marginTop: '10px' }}>
                            <div className='control'>
                                <button
                                    className='button is-danger is-rounded is-fullwidth'
                                    onClick={handleDelete}
                                >
                                    Delete
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default EditForm;
