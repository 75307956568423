import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { Config } from '../.env.js';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const CampaignForm = () => {
    const [AuthName, setAuthName] = useState('');
    const [AuthContact, setAuthContact] = useState('');
    const [AuthEmail, setAuthEmail] = useState('');
    const [brandName, setBrandName] = useState('');
    const [users, setUsers] = useState([]);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    // Fetch users from the backend
    useEffect(() => {
        const fetchUsers = async () => {
            try {
                const response = await fetch(`${Config.API_URL}/api/users`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                });

                if (!response.ok) {
                    throw new Error('Failed to fetch users');
                }

                const usersData = await response.json();
                setUsers(usersData);
            } catch (error) {
                console.error('Error fetching users:', error);
            }
        };

        fetchUsers();

        // Load form data from localStorage if available
        const saveBrandName = localStorage.getItem(brandName)
        const savedAuthName = localStorage.getItem('AuthName');
        const savedAuthContact = localStorage.getItem('AuthContact');
        const savedAuthEmail = localStorage.getItem('AuthEmail');
        const savedUsers = JSON.parse(localStorage.getItem('users'));

        if (saveBrandName) setBrandName(saveBrandName)
        if (savedAuthName) setAuthName(savedAuthName);
        if (savedAuthContact) setAuthContact(savedAuthContact);
        if (savedAuthEmail) setAuthEmail(savedAuthEmail);
        if (savedUsers) setUsers(savedUsers);
    }, []);

    // Handle form submission
    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        // Collect selected managers and leads
        const managers = users.filter(user => user.role !== 'Lead' && user.isChecked).map(user => user.userName);
        const leads = users.filter(user => user.role === 'Lead' && user.isChecked).map(user => user.userName);

        // Construct form data object
        const formData = {
            brandName,
            name: AuthName,
            campaignName: AuthContact,
            campaignRulesDescription: AuthEmail,
            managers, // Array of selected manager names
            leads     // Array of selected lead names
        };

        try {
            // Send form data to the backend
            const response = await axios.post(`${Config.API_URL}/api/campaigns`, formData);
            console.log('Form data submitted', response.data);
            // Navigate to another page or show success message
            navigate('/campaign2', { state: { campaignName: AuthContact, managers } });
            console.log(AuthContact);
        } catch (error) {
            console.error('Error submitting form', error);
        } finally {
            setLoading(false);
        }
    };

    // Handle checkbox change
    const handleCheckboxChange = (id) => (event) => {
        const updatedUsers = users.map(user =>
            user._id === id ? { ...user, isChecked: event.target.checked } : user
        );
        setUsers(updatedUsers);

        // Save updated users to localStorage
        localStorage.setItem('users', JSON.stringify(updatedUsers));
    };

    const handleChange = (value) => {
        setAuthEmail(value);

        // Save campaign rules description to localStorage
        localStorage.setItem('AuthEmail', value);
    };

    const handleInputChange = (e, setter, storageKey) => {
        const value = e.target.value;
        setter(value);

        // Save value to localStorage
        localStorage.setItem(storageKey, value);
    };

    return (
        <div className='container'>
            {loading && (
                <div className="loader-overlay">
                    <div className="loader"></div>
                </div>
            )}
            <div className='container1 mt-5'>
                <figure className="image is-64x64"></figure>
            </div>
            <form onSubmit={handleSubmit}>
                <div className="field">
                    <h1 className='title has-text-centered mt-5'>Campaign</h1>
                </div>
                <div className='field is-small'>
                    <label className="label has-text-left">Brand Name*</label>
                    <div className="control">
                        <input
                            className="input is-rounded"
                            type="text"
                            placeholder="Brand Name"
                            value={brandName}
                            onChange={(e) => handleInputChange(e, setBrandName, 'brandName')}
                        />
                    </div>
                </div>
                <div className='field is-small'>
                    <label className="label has-text-left">Campaign Name*</label>
                    <div className="control">
                        <input
                            className="input is-rounded"
                            type="text"
                            placeholder="Campaign Name"
                            value={AuthContact}
                            onChange={(e) => handleInputChange(e, setAuthContact, 'AuthContact')}
                        />
                    </div>
                </div>
                <div className='field'>
                    <label className="label has-text-left">Campaign Rules Description*</label>
                    <div className="control">
                        <ReactQuill
                            value={AuthEmail}
                            onChange={handleChange}
                            placeholder="Campaign rule description"
                            modules={{
                                toolbar: [
                                    [{ 'header': '1' }, { 'header': '2' }, { 'font': [] }],
                                    [{ 'list': 'ordered' }, { 'list': 'bullet' }],
                                    ['bold', 'italic', 'underline'],
                                    [{ 'align': [] }],
                                    ['link'],
                                    ['blockquote'],
                                    [{ 'script': 'sub' }, { 'script': 'super' }],
                                    ['code'],
                                    [{ 'indent': '-1' }, { 'indent': '+1' }],
                                    ['clean']
                                ],
                            }}
                            style={{ borderRadius: "20px" }}
                        />
                    </div>
                </div>
                <div className='field'>
                    <div className="control">
                        <div className='column'>
                            <div className='my-2 has-text-left'>
                                <strong>Choose Your Campaign Manager(s)*</strong>
                            </div>
                            {users.filter(user => user.role !== 'Lead').map(user => (
                                <div key={user._id} className="checkboxContainer" style={{ display: 'inline-flex', flexDirection: 'row', marginRight: '8px' }}>
                                    <input
                                        type="checkbox"
                                        checked={user.isChecked || false}
                                        onChange={handleCheckboxChange(user._id)}
                                        className="checkbox"
                                    />
                                    <label className="checkboxLabel" style={{ marginLeft: '8px' }}>{user.userName}</label>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
                <div className='field'>
                    <div className="control">
                        <div className='column'>
                            <div className='my-2 has-text-left'>
                                <strong>Choose Your Lead(s)*</strong>
                            </div>
                            {users.filter(user => user.role === 'Lead').map(user => (
                                <div key={user._id} className="checkboxContainer" style={{ display: 'inline-flex', flexDirection: 'row', marginRight: '9px' }}>
                                    <input
                                        type="checkbox"
                                        checked={user.isChecked || false}
                                        onChange={handleCheckboxChange(user._id)}
                                        className="checkbox"
                                    />
                                    <label className="checkboxLabel" style={{ marginLeft: '8px' }}>{user.userName}</label>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
                <div className="field">
                    <div className="control">
                        <button className="button is-primary is-fullwidth mt-5 is-rounded" type="submit">   {loading ? (
                            <div className="loader"></div> // Loader
                        ) : (
                            'Submit'
                        )}</button>
                    </div>
                </div>
            </form>
        </div>
    );
};

export default CampaignForm;
