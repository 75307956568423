import React, { useState } from "react";


const BankDetails = ({
    accountNumber,
    upi,
    ifsc,
    BeneficiaryName,
    BankName,
    onAccountNumberChange,
    onUpiChange,
    onIfscChange,
    onBeneficiaryNameChange,
    onBankNameChange,
}) => {
    // const [accountNumber, setaccountNumber] = useState(false);
    // const [upi, setUpi] = useState(false);
    // const [ifsc, setifsc] = useState(false);
    // const [BeneficiaryName, setBeneficiaryName] = useState(false);
    // const [BankName, setBankName] = useState(false);

    return (
        <div>
            <div className="field is-small">
                <label className="checkbox">
                    <input
                        type="checkbox"
                        checked={accountNumber}
                        onChange={(e) => onAccountNumberChange(e.target.checked)}
                    />
                    Bank Account Number
                </label>
            </div>
            <div className="field is-small">
                <label className="checkbox">
                    <input
                        type="checkbox"
                        checked={upi}
                        onChange={(e) => onUpiChange(e.target.checked)}
                    />
                    UPI
                </label>
            </div>
            <div className="field is-small">
                <label className="checkbox">
                    <input
                        type="checkbox"
                        checked={ifsc}
                        onChange={(e) => onIfscChange(e.target.checked)}
                    />
                    Ifsc Code
                </label>
            </div>
            <div className="field is-small">
                <label className="checkbox">
                    <input
                        type="checkbox"
                        checked={BeneficiaryName}
                        onChange={(e) => onBeneficiaryNameChange(e.target.checked)}
                    />
                    Beneficiary Name
                </label>
            </div>
            <div className="field is-small">
                <label className="checkbox">
                    <input
                        type="checkbox"
                        checked={BankName}
                        onChange={(e) => onBankNameChange(e.target.checked)}
                    />
                    Bank Name
                </label>
            </div>
        </div>
    )
}
export default BankDetails;