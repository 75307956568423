import React from 'react';
import { Link } from 'react-router-dom';
import './style.css'; // Ensure your styles are updated accordingly

const Redirect = () => {
    return (
        <div className="thank-you-container">
            <div className="thank-you-content">
                <h1 className="thank-you-title">Thank You!</h1>
                <p className="thank-you-text">Waiting for approval.</p>
                <Link to="/signin">
                    <button className="button is-primary is-medium cta-button">
                        Sign In Now
                    </button>
                </Link>
            </div>
        </div>
    );
};

export default Redirect;
