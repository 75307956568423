import React, { useState } from "react";

const EditablePlaceholderInput = () => {
    const [deliveryCharge, setDeliveryCharge] = useState(
        "Product Amount with Delivery Charges (optional)"
    );
    const [inputValue2, setInputValue2] = useState('Delivery Fee (optional)');
    const [inputValue1, setInputValue1] = useState('Quantity');
    const [inputValue3, setInputValue3] = useState('Screen Shot');

    return (
        <div style={{ display: "flex", alignItems: "center" }}>
            {/* Show question heading only when `deliveryCharge` is not empty */}
            {deliveryCharge && (
                <span style={{ marginLeft: "10px", marginRight: "5px" }}>

                </span>
            )}

            <div className="field" style={{ marginRight: "10px" }}>
                <label className="label" style={{ marginBottom: "0px" }}>Optional</label>
                <div className="control">
                    <input
                        className="input is-small"
                        type="text"
                        value={deliveryCharge}
                        onChange={(e) => setDeliveryCharge(e.target.value)}
                        placeholder={deliveryCharge} // Dynamically set placeholder as the question
                        style={{
                            display: "inline",
                            width: "auto",
                            marginLeft: "5px",
                            marginRight: "5px",
                        }}
                    />
                </div>
            </div>
            {deliveryCharge && (
                <span style={{ marginLeft: "10px", marginRight: "5px" }}>

                </span>
            )}
            <span style={{ marginLeft: '10px', marginRight: '5px' }}>with</span>
            <div className="field" style={{ marginRight: "10px" }}>
                <label className="label" style={{ marginBottom: "0px" }}>Optional</label>
                <div className="control">
                    <input
                        className="input is-small"
                        type="text"
                        value={inputValue2}
                        onChange={(e) => setInputValue2(e.target.value)}
                        placeholder={inputValue2} // Dynamically set placeholder as the question
                        style={{
                            display: "inline",
                            width: "auto",
                            marginLeft: "5px",
                            marginRight: "5px",
                        }}
                    />
                </div>
            </div>
            <span style={{ marginLeft: '10px', marginRight: '5px' }}>with</span>
            <div className="field" style={{ marginRight: "10px" }}>
                <label className="label" style={{ marginBottom: "0px" }}>Optional</label>
                <div className="control">
                    <input
                        className="input is-small"
                        type="text"
                        value={inputValue1}
                        onChange={(e) => setInputValue1(e.target.value)}
                        placeholder={inputValue1} // Dynamically set placeholder as the question
                        style={{
                            display: "inline",
                            width: "auto",
                            marginLeft: "5px",
                            marginRight: "5px",
                        }}
                    />
                </div>
            </div>
            <span style={{ marginLeft: '10px', marginRight: '5px' }}>with</span>
            <div className="field" style={{ marginRight: "10px" }}>
                <label className="label" style={{ marginBottom: "0px" }}>Optional</label>
                <div className="control">
                    <input
                        className="input is-small"
                        type="text"
                        value={inputValue3}
                        onChange={(e) => setInputValue3(e.target.value)}
                        placeholder={inputValue3} // Dynamically set placeholder as the question
                        style={{
                            display: "inline",
                            width: "auto",
                            marginLeft: "5px",
                            marginRight: "5px",
                        }}
                    />
                </div>
            </div>
        </div>
    );
};

export default EditablePlaceholderInput;
