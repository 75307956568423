import React, { useState } from 'react'
import './App.css';
import { BrowserRouter, Routes, Route, Navigate, useNavigate } from 'react-router-dom';
import AdminDashboard from './pages/AdminDashboard';
import Admin from './pages/AdminDashboard';
import LeadDashboard from './pages/Dasboard/LeadDashboard';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import CampaignMangerDashboard from './pages/CampaignMnager'
import PaymentApproveDashboard from './pages/Dasboard/PaymentApproveDashboard';
import CampaignForm from './pages/CampaignCreateForm';
import ConformationForm from './pages/ConformationForm';
import Table from './pages/masterTable';
import CampaignForm2 from './pages/ProductEntity';
import OrderForm from './pages/orderForm'
import Signup from './Auth/signUp';
import OTP from './pages/Otp'
import Refund from './pages/refundForm'
import AllCamp from './pages/allCampaign';
import Live from './pages/live';
import OrderTable from './pages/orderTable';
import ForgetPassword from './Auth/forgotPassword';
import SignIn from './Auth/signIn';
import Home from './pages/Home';
import OtpPage from './pages/otpPage';


import UserManagement from './pages/userRole';
import Modal from './pages/invite';
import UserTable from './pages/userTable';
import InviteForm1 from './pages/EditInvite';
import DataDisplay from './pages/dataDisplay'
import RefundTemp from './pages/tempRefund';
import TblOrder from './pages/tblOrder';
import SuccessPage from './pages/successpage'
import AdminDashboardDummy from './pages/dumyAdmindashboard';
import Redirect from './pages/redirectionpage'
import UpdatePassword from './pages/updatePassword'
import Card from './pages/card'
import CustomerCard from './pages/custocard';
import LiveCustomer from './pages/livecustomer'
import ApproverTable from './pages/orderPaymentAprroverTable'
import CampaignDetails from './pages/campaignDetails'
// App.js or index.js
import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons'; // Solid icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import '@fortawesome/fontawesome-free/css/all.min.css';


library.add(fas); // Add all solid icons to the library

const App = () => {
  const [showHeader, setShowHeader] = useState(false);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  return (
    <div className='hero'>
      <BrowserRouter>
        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='/admin' element={<AdminDashboard />} />
          <Route path='/lead' element={<LeadDashboard />} />
          <Route path='/campaignManger' element={<CampaignMangerDashboard />} />
          <Route path='/approver' element={<PaymentApproveDashboard />} />
          <Route path='/conformation' element={<ConformationForm />} />
          <Route path='/table' element={<Table />} />
          <Route path='/campaign2' element={<CampaignForm2 />} />
          <Route path='/live' element={<OrderForm />} />
          <Route path="/signup" element={<Signup />} />
          <Route path="/otp" element={<OTP />} />
          <Route path="/live-campaign" element={<Live />} />
          <Route path="/data" element={<DataDisplay />} />
          <Route path="/forgot-password" element={<ForgetPassword />} />
          <Route path="/signin" element={<SignIn />} />
          <Route path="/resendotp" element={<OrderTable />} />
          <Route path="/otp1" element={<OtpPage />} />
          <Route path="/userRole" element={<UserManagement />} />
          <Route path="/modal" element={<Modal />} />
          <Route path="/user-table" element={<UserTable />} />
          <Route path="/editInvite" element={<InviteForm1 />} />
          <Route path="/" element={<AllCamp />} />
          <Route path="/order-form" element={<OrderForm />} />
          <Route path="/refund-form" element={<Refund />} />
          <Route path="/order-table" element={<OrderTable />} />
          <Route path="/template-refund" element={<RefundTemp />} />
          <Route path="/campaignForm" element={<CampaignForm />} />
          <Route path="/tblOrder" element={<TblOrder />} />
          <Route path="/success" element={< SuccessPage />} />
          {/* <Route path="/admindumy" component={<AdminDashboardDummy/>} /> */}
          <Route path="/redirect" element={< Redirect />} />
          <Route path="/lead" element={< LeadDashboard />} />
          {/* <Route path="/approver" component={<PaymentApproveDashboard/>} /> */}
          <Route path='/card' element={< Card />} />
          <Route path='/update-password' element={<UpdatePassword />} />
          <Route path="/card2" element={<CustomerCard />} />
          <Route path="/live-user" element={<LiveCustomer />} />
          <Route path="/table-approver" element={<ApproverTable />} />
          <Route path="/campaignDetails" element={<CampaignDetails />} />
        </Routes>
        <ToastContainer />
      </BrowserRouter>

    </div>
  )
}

export default App
