import React, { useState } from 'react';
import axios from 'axios';
import { Config } from '../.env.js';
import { useLocation, useNavigate } from 'react-router-dom';
import EditablePlaceholderInput from './editable.js';
import BankDetails from './bankDetails.js';
import "./style.css";

const ConformationForm = () => {
  const navigate = useNavigate();
  const [AuthInstagram, setAuthInstagram] = useState(false);
  const [AuthFacebook, setAuthFacebook] = useState(false);
  const [AuthTwitter, setAuthTwitter] = useState(false);
  const [AuthYouTube, setAuthYouTube] = useState(false);
  const [AuthCustomSocial, setAuthCustomSocial] = useState('');
  const [customMarketPlaceName, setCustomMarketPlaceName] = useState('');
  const [customForoum, setCustomForoum] = useState('');
  const [FullName, setFullName] = useState(false);
  const [review, setReview] = useState(''); // Initialize as an empty string
  const [gender, setGender] = useState(false);
  const [langaugeSpeek, setlangaugeSpeek] = useState(false);
  const [langaugeWrite, setlangaugeWrite] = useState(false);
  const [student, setStudent] = useState(false);
  const [productSS, setProductSS] = useState(false);
  const [order, setOrder] = useState(false);
  const [city, setCity] = useState(false);
  const [state, setState] = useState(false);
  const [pinCode, setPinCode] = useState(false);
  const [accountNumber, setaccountNumber] = useState(false);
  const [upi, setUpi] = useState(false);
  const [ifsc, setifsc] = useState(false);
  const [BeneficiaryName, setBeneficiaryName] = useState(false);
  const [BankName, setBankName] = useState(false);
  const [whatsappGroup, setWhatsappGroup] = useState(false);
  const [manager, setManager] = useState(false);
  const [Phone, setPhone] = useState(false);
  const [date, setDate] = useState(false);
  const [college, setCollege] = useState(false);
  const [whatsapp, setWhatsapp] = useState(false);
  const [email, setEmail] = useState(false);
  const [profileType, setprofileType] = useState('');
  const [submitedDate, setsubmitedDate] = useState('');
  const [products, setProducts] = useState([
    { name: '', quantity: '', ss: null, amount: '' }
  ]);
  const [inputValue, setInputValue] = useState('Ordered Products/Reviewed Page/Post/Link');
  const [inputValue1, setInputValue1] = useState('Quantity');
  const [inputValue2, setInputValue2] = useState('Delivery Fee (optional)');
  const [deliveryCharge, setDeliveryCharge] = useState('Product Amount with Delivery Charges (optional)');
  const [inputValue3, setInputValue3] = useState('Screen Shot');
  const [inputValue4, setInputValue4] = useState('Ordered Products/Reviewed Page/Post/Link');
  const [profileVisibility, setProfileVisibility] = useState('public');
  const [loading, setLoading] = useState(false);

  const location = useLocation();
  const { campaignName } = location.state || {};
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    const formData = {
      AuthInstagram,
      AuthFacebook,
      AuthTwitter,
      AuthYouTube,
      customMarketPlaceName,
      FullName,
      gender,
      langaugeWrite,
      langaugeSpeek,
      college,
      whatsapp,
      email,
      date,
      manager,
      whatsappGroup,
      pinCode,
      state,
      accountNumber,
      upi,
      ifsc,
      BeneficiaryName,
      BankName,
      productSS,
      inputValue,
      inputValue1,
      inputValue2,
      inputValue3,
      inputValue4,
      order,
      city,
      Phone,
      student,
      review,
      customForoum,
      AuthCustomSocial,
      profileType,
      submitedDate,
      deliveryCharge,
      campaignName,
    };
    try {
      const response = await axios.post(`${Config.API_URL}/api/FormData/${campaignName}`, formData);
      console.log('Form data submitted:', response.data);
      // navigate('/template-refund', { state: { campaignName } });
      navigate('/template-refund', { state: { order, email, Phone, campaignName } });
    } catch (error) {
      console.error('Error submitting form data:', error);
    } finally {
      setLoading(false);
    }
  };
  const handleInputChange = (index, event) => {
    const values = [...products];
    if (event.target.name === "ss") {
      values[index][event.target.name] = event.target.files[0];
    } else {
      values[index][event.target.name] = event.target.value;
    }
    setProducts(values);
  };
  const handleAddFields = () => {
    setProducts([...products, { name: '', quantity: '', ss: null, amount: '' }]);
  };

  const handleRemoveFields = index => {
    const values = [...products];
    values.splice(index, 1);
    setProducts(values);
  };
  return (
    <div className='container'>
      <div className='container1'>
        {loading && (
          <div className="loader-overlay">
            <div className="loader"></div>
          </div>
        )}
        <figure className="image is-64x64">
          {/* <img src={logo} alt="Logo" /> */}
        </figure>
      </div>
      <form onSubmit={handleSubmit}>
        <h1 className='title has-text-centered mb-3'>Order Form Setup Form</h1>
        <p><strong>Campaign Name:</strong> {campaignName}</p>
        <div className="field is-small">
          <label className="checkbox">
            <input
              type="checkbox"
              checked={FullName}
              onChange={(e) => setFullName(e.target.checked)}
            />
            Your Full Name
          </label>
        </div>
        <div className="field is-small">
          <label className="checkbox">
            <input
              type="checkbox"
              checked={AuthInstagram}
              onChange={(e) => setAuthInstagram(e.target.checked)}
            />
            Your Instagram User Name
          </label>
        </div>
        <div className="field is-small">
          <label className="checkbox">
            <input
              type="checkbox"
              checked={AuthYouTube}
              onChange={(e) => setAuthYouTube(e.target.checked)}
            />
            Your YouTube User Name
          </label>
        </div>
        <div className="field is-small">
          <label className="checkbox">
            <input
              type="checkbox"
              checked={AuthTwitter}
              onChange={(e) => setAuthTwitter(e.target.checked)}
            />
            Your Twitter User Name
          </label>
        </div>

        <div className="field is-small">
          <label className="checkbox">
            <input
              type="checkbox"
              checked={AuthFacebook}
              onChange={(e) => setAuthFacebook(e.target.checked)}
            />
            Your Facebook User Name
          </label>
        </div>
        <div className="field is-small">
          <label className="checkbox">
            <input
              type="checkbox"
            />
            Your
            <input
              className="input is-small"
              type="text"
              value={AuthCustomSocial}
              onChange={(e) => setAuthCustomSocial(e.target.value)}
              placeholder=" Custom Social Media Platform"
              style={{ display: 'inline', width: 'auto', marginLeft: '5px', marginRight: '5px' }}
            />
            User Name
          </label>
        </div>
        <div className="field is-small">
          <label className="checkbox">
            <input
              type="checkbox"
            />
            Your
            <input
              className="input is-small"
              type="text"
              value={customMarketPlaceName}
              onChange={(e) => setCustomMarketPlaceName(e.target.value)}
              placeholder="Custom MarketPlace Name"
              style={{ display: 'inline', width: 'auto', marginLeft: '5px', marginRight: '5px' }}
            />
            Account Name
          </label>
        </div>
        <div className="field is-small">
          <label className="checkbox">
            <input
              type="checkbox"
            />
            Your
            <input
              className="input is-small"
              type="text"
              value={customForoum}
              onChange={(e) => setCustomForoum(e.target.value)}
              placeholder="Custom Forum"
              style={{ display: 'inline', width: 'auto', marginLeft: '5px', marginRight: '5px' }}
            />
            Profile Link
          </label>
        </div>
        <div className="field is-small">
          <label className="checkbox">
            <input
              type="checkbox"
              checked={email}
              onChange={(e) => setEmail(e.target.checked)}
            />
            Your Email
          </label>
        </div>
        <div className="field is-small">
          <label className="checkbox">
            <input
              type="checkbox"
              checked={Phone}
              onChange={(e) => setPhone(e.target.checked)}
            />
            Your Phone Number
          </label>
        </div>

        <div className="field is-small">
          <label className="checkbox">
            <input
              type="checkbox"
              checked={whatsapp}
              onChange={(e) => setWhatsapp(e.target.checked)}
            />
            Your Whatsapp Number
          </label>
        </div>
        <div className="field is-small">
          <label className="checkbox">
            <input
              type="checkbox"
              checked={gender}
              onChange={(e) => setGender(e.target.checked)}
            />
            Your Gender
          </label>
        </div>

        <div className="field is-small">
          <label className="checkbox">
            <input
              type="checkbox"
              checked={langaugeSpeek}
              onChange={(e) => setlangaugeSpeek(e.target.checked)}
            />
            Which language can you speek?
          </label>
        </div>
        <div className="field is-small">
          <label className="checkbox">
            <input
              type="checkbox"
              checked={langaugeWrite}
              onChange={(e) => setlangaugeWrite(e.target.checked)}
            />
            Which language can you write?
          </label>
        </div>
        <div className="field is-small">
          <label className="checkbox">
            <input
              type="checkbox"
            />
            Is your
            <input
              className="input is-small"
              type="text"
              value={profileType}
              onChange={(e) => setprofileType(e.target.value)}
              placeholder="select type"
              style={{ display: 'inline', width: 'auto', marginLeft: '5px', marginRight: '5px' }}
            />
            Profile Public/Private
          </label>


        </div>


        <div className="field is-small">
          <label className="checkbox">
            <input
              type="checkbox"
              checked={order}
              onChange={(e) => setOrder(e.target.checked)}
            />
            Order Id
          </label>
        </div>
        <div className="field is-small">
          <label className="checkbox">
            <input
              type="checkbox"
              checked={date}
              onChange={(e) => setDate(e.target.checked)}
            />
            Order Date
          </label>
        </div>
        <div className="field is-small">
          <label className="checkbox">
            <input
              type="checkbox"
            />
            <input
              className="input is-small"
              type="text"
              value={submitedDate}
              onChange={(e) => setsubmitedDate(e.target.value)}
              placeholder="01-01-2025"
              style={{ display: 'inline', width: 'auto', marginLeft: '5px', marginRight: '5px' }}
            />
            Submission Date
          </label>
        </div>
        <div className="field is-small">
          <label className="checkbox">
            <input
              type="checkbox"
            />
            How Many
            <input
              className="input is-small"
              type="text"
              value={review} // bind to `review` state
              onChange={(e) => setReview(e.target.value)} // update `review` state
              placeholder="Custom MarketPlace Name"
              style={{ display: 'inline', width: 'auto', marginLeft: '5px', marginRight: '5px' }}
            />
            Reviews you do per month
          </label>
        </div>



        <div className="field is-small">
          <label className="checkbox">
            <input
              type="checkbox"
              checked={student}
              onChange={(e) => setStudent(e.target.checked)}
            />
            Are you a Student?
          </label>
        </div>
        <div className="field is-small">
          <label className="checkbox">
            <input
              type="checkbox"
              checked={college}
              onChange={(e) => setCollege(e.target.checked)}
            />
            If yes, Your College Name
          </label>
        </div>
        <div className="field is-small">
          <label className="checkbox">
            <input
              type="checkbox"
              checked={manager}
              onChange={(e) => setManager(e.target.checked)}
            />
            Choose Your Manager
          </label>
        </div>
        <div className="field is-small">
          <label className="checkbox">
            <input
              type="checkbox"
              checked={city}
              onChange={(e) => setCity(e.target.checked)}
            />
            City
          </label>
        </div>
        <div className="field is-small">
          <label className="checkbox">
            <input
              type="checkbox"
              checked={state}
              onChange={(e) => setState(e.target.checked)}
            />
            State
          </label>
        </div>
        <div className="field is-small">
          <label className="checkbox">
            <input
              type="checkbox"
              checked={pinCode}
              onChange={(e) => setPinCode(e.target.checked)}
            />
            Pin Code
          </label>
        </div>
        <BankDetails
          accountNumber={accountNumber}
          upi={upi}
          ifsc={ifsc}
          BeneficiaryName={BeneficiaryName}
          BankName={BankName}
          onAccountNumberChange={setaccountNumber}
          onUpiChange={setUpi}
          onIfscChange={setifsc}
          onBeneficiaryNameChange={setBeneficiaryName}
          onBankNameChange={setBankName}
        />



        <div className="field is-small is-flex is-align-items-center">
          <label className="checkbox" style={{ marginRight: '10px' }}>
            <input
              type="checkbox"
              checked={productSS}
              onChange={(e) => setProductSS(e.target.checked)}
            />
            Choose
          </label>

          <input
            className="input is-small"
            type="text"
            value={inputValue}
            onChange={(e) => setInputValue(e.target.value)}
            placeholder="Ordered Products/Reviewed Page/Post/Link"
            style={{ display: 'inline', width: 'auto', marginLeft: '5px', marginRight: '5px' }}
          />
          <span style={{ marginLeft: '10px', marginRight: '5px' }}>with</span>
          <EditablePlaceholderInput
            deliveryCharge={deliveryCharge}
            inputValue1={inputValue1}
            inputValue2={inputValue2}
            inputValue3={inputValue3}
            onDeliveryChargeChange={setDeliveryCharge}
            onInputValue1Change={setInputValue1}
            onInputValue2Change={setInputValue2}
            onInputValue3Change={setInputValue3} />
          <span style={{ marginLeft: '10px', marginRight: '5px' }}>for each Ordered Products/Reviewed Page/Post/Link</span>
        </div>

        <div className="field is-small mt-3">
          <label className="checkbox">
            <input
              type="checkbox"
              checked={whatsappGroup}
              onChange={(e) => setWhatsappGroup(e.target.checked)}
            />
            Have you joined WhatsApp Group
          </label>
        </div>
        <div className="field is-small">
          <label className="checkbox">
            <input
              type="checkbox"
            // checked={city}
            // onChange={(e) => setCity(e.target.checked)}
            />
            Agree to{' '}
            <a href="//hobo.video/terms-conditions-for-an-influencer" target="_blank" rel="noopener noreferrer">
              Terms and Conditions
            </a>
          </label>
        </div>
        <div className="field">
          <div className="control">
            <button className="button is-primary is-rounded is-fullwidth mt-5" type="submit">   {loading ? (
              <div className="loader"></div> // Loader
            ) : (
              'Submit'
            )}</button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default ConformationForm;
