import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios'; // Import axios
import { toast } from 'react-toastify';
import { Config } from '../.env.js';
import 'react-toastify/dist/ReactToastify.css';
import "../pages/style.css";

const SignIn = () => {
    const [Email, setEmail] = useState("");
    const [Password, setPassword] = useState("");
    const [loading, setLoading] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");

    const navigate = useNavigate();

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    const handlesignin = async (e) => {
        e.preventDefault();
        try {
            setLoading(true);
            setErrorMessage("");

            // Make API request directly using axios
            const res = await axios.post(`${Config.API_URL}/login`, {
                email: Email,
                password: Password
            });
            if (res.data.error) {
                toast.error(res.data.error); // Display the error using toast
                setErrorMessage(res.data.error); // Set the error message for display
                return; // Stop further processing
            }
            if (res.data && !res.data.error) {
                const { role: userRole, yourName: userName, token, status } = res.data; // Updated destructuring

                if (!userRole || !userName || !token) {
                    toast.error("Unexpected response from server");
                    return;
                }
                if (status === 'pending') {
                    setErrorMessage("Your approval is still pending.");
                    return; // Prevent further processing
                }
                // Store values in localStorage
                localStorage.setItem('userRole', userRole);
                localStorage.setItem('loggedInUserName', userName);
                localStorage.setItem('token', token);


                // Redirect based on the user role
                switch (userRole) {
                    case 'Admin':
                        navigate("/admin", { replace: true });
                        break;
                    case 'Lead':
                        navigate("/lead", { replace: true });
                        break;
                    case 'Campaign_Manger':
                        navigate("/campaignManger", { replace: true });
                        break;
                    case 'Pyment_Approver':
                        navigate("/approver", { replace: true });
                        break;
                    default:
                        toast.error("Invalid role detected!");
                }
            } else {
                toast.error(res.data.error || "Login failed");
            }
        } catch (err) {
            // Handle errors like network issues, etc.
            toast.error("Your approval is still pending.");
            console.log("Error: ", err);
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className='thank-you-container'>
            {loading && (
                <div className="loader-overlay">
                    <div className="loader"></div>
                </div>
            )}
            {errorMessage && (
                <div className="notification is-danger">
                    <p>{errorMessage}</p>
                </div>
            )}

            <div className='control my-input' style={{ marginLeft: "27rem" }}>

                <h1 className='title mt-5 has-text-white'>Sign In to Your Dashboard</h1>
                <form>
                    <div className='my-2 has-text-left my-input'>
                        <label className='label has-text-white'>Your Business Email Registered With hobo</label>
                    </div>
                    <div className='field'>
                        <div className='control has-icons-left my-input'>
                            <input
                                className='input is-rounded'
                                type='text'
                                placeholder='Email ID'
                                value={Email}
                                onChange={(e) => setEmail(e.target.value)}
                            />
                            <span className="icon is-small is-left">
                                <i className="fas fa-envelope"></i>
                            </span>
                        </div>
                    </div>
                    <div className='my-2 has-text-left my-input'>
                        <label className='label has-text-white'>Password</label>
                    </div>
                    <div className='field'>
                        <div className="control has-icons-left has-icons-right is-flex my-input">
                            <input
                                className="input is-rounded"
                                type={showPassword ? 'text' : 'password'}
                                placeholder="Password"
                                value={Password}
                                onChange={(e) => setPassword(e.target.value)}
                            />
                            <span className="icon is-small is-left">
                                <i className="fas fa-key"></i>
                            </span>
                            <span className="icon is-small is-right" onClick={togglePasswordVisibility} style={{ cursor: 'pointer', 'pointer-events': 'all' }}>
                                <i className={`fas fa-eye${showPassword ? '-slash' : ''}`}></i>
                            </span>
                        </div>
                    </div>
                    <div className='Link mb-3'>
                        <Link to='/forgot-password'>
                            <p className='has-text-right my-input'>Forgot Your Password?</p>
                        </Link>
                    </div>
                    <div className='field'>
                        <div className='control my-input'>
                            <button
                                className='button is-primary is-rounded is-focused is-medium is-fullwidth'
                                disabled={!Email || !Password || loading}
                                type='submit'
                                onClick={handlesignin}
                            >
                                {loading ? (
                                    <div className="loader"></div> // Loader
                                ) : (
                                    'SIGN IN'
                                )}
                            </button>
                        </div>
                    </div>
                </form>
                <div className='is-flex has-text-left mr-5 mt-3 my-input'>
                    <p className="has-text-white">Don't have an account?</p>
                    <Link to='/signup'>
                        <span className='has-text-weight-bold ml-2'>Sign Up</span>
                    </Link>
                </div>
            </div>
        </div>
    );
};

export default SignIn;
